import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: '/smart-sports'
  },
  {
    path: '/smart-sports',
    name: 'SmartSports',
    component: () => import(/* webpackChunkName: "SmartSports" */ '../views/bigScreen/views/smartSports.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/bigScreen/views/login/login.vue')
  },
  {
    path: '/analysis-system',
    name: 'AnalysisSystem',
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "AnalysisSystem" */ '../views/bigScreen/views/analysisSystem.vue')
  },
  {
    path: '/test-report',
    name: 'testReport',
    component: () => import(/* webpackChunkName: "testReport" */ '../views/bigScreen/views/testReport.vue')
  },
  {
    path: '/sport-load-monitoring-system',
    name: 'sportLoadMonitoringSystem',
    component: () => import(/* webpackChunkName: "sportLoadMonitoringSystem" */ '../views/bigScreen/views/sportLoadMonitoringSystem.vue')
  },
  {
    path: '/class-room-report',
    name: 'classRoomReport',
    component: () => import(/* webpackChunkName: "classRoomReport" */ '../views/bigScreen/views/classRoomReport.vue')
  },
  {
    path: '/class-report',
    name: 'roomReport',
    component: () => import(/* webpackChunkName: "roomReport" */ '../views/bigScreen/views/roomReport.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 在每次路由导航触发前执行的逻辑  
  let token = localStorage.getItem('token')
  console.log(token, 'token')
  if (token) {
    next()
  } else {
    if (to.path == '/login') {
      next()
    } else {
      sessionStorage.setItem('path', to.path)
      sessionStorage.setItem('query', JSON.stringify(to.query))
      next({ path: '/login' })
    }
  }
});

export default router
