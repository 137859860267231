import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from "echarts";
import { Pose } from "@mediapipe/pose";




Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false

Vue.use(ElementUI);

//加载阿里云文件
Vue.prototype.$pose = new Pose({
  locateFile: (file) => {
    return `https://sisheng-ai.oss-cn-shenzhen.aliyuncs.com/model/@mediapipe/pose/${file}`;
  },
});

// 第二个检测器
Vue.prototype.$poseTwo = new Pose({
  locateFile: (file) => {
    return `https://sisheng-ai.oss-cn-shenzhen.aliyuncs.com/model/@mediapipe/pose/${file}`;
  },
});


// medpipe 参数设置
Vue.prototype.$pose.setOptions({
  modelComplexity: 1,
  smoothLandmarks: true,
  enableSegmentation: true,
  smoothSegmentation: true,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
});

Vue.prototype.$poseTwo.setOptions({
  modelComplexity: 1,
  smoothLandmarks: true,
  enableSegmentation: true,
  smoothSegmentation: true,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
});



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
