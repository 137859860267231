import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    studentList: [],
    classTimeArr: [],
    averageRateArrs: [],
    manAverageRateArrs: [],
    femaleAverageRateArrs: []
  },
  getters: {
  },
  mutations: {
    setStudentList(state, list){
      state.studentList = list
    },
    setClassTimeArr(state, list){
      state.classTimeArr = list
    },
    setAverageArr(state, { key, value }){
      state[key] = value
    }
  },
  actions: {
  },
  modules: {
  }
})
