<template>
  <div id="app">
    <!-- 使用路由 实现页面缓存 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import student from "../src/views/bigScreen/assets/json/student.json";
import { rand, summation } from "@/utils/utils";

export default {
  name: "App",
  data() {
    return {
      interTime: null,
      studentList: [],
      sexList: ["男", "女"],
      time: 0,
      timeArr: [0],
      rateArrs: [],
      averageRateArrs: [],
      manAverageRateArrs: [],
      femaleAverageRateArrs: [],
      classTime: 45 * 60, // 45 分钟 单位 秒
      targetTime: 0,
    };
  },
  computed: {
    minute() {
      return Math.floor((this.time / 60) % 60);
      // return Math.floor(this.time % 60);
    },
  },
  created() {
    // 时间重新赋值
    // let classTime = window.sessionStorage.getItem("classTime");
    // this.time = classTime ? classTime * 1 : 0;

    this.$nextTick(() => {
      this.resizeFun();
      window.addEventListener("resize", this.resizeFun);
    });

    if (this.interTime) {
      clearInterval(this.interTime);
    }
    this.setRateData();
    this.interTime = setInterval(() => {
      this.time += 5;
      this.timeArr.push(this.time);
      this.setRateData();
    }, 5000);
  },
  methods: {
    resizeFun() {
      const devicePixelRatio = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (devicePixelRatio !== 1) {
        // 如果在笔记本中用IE浏览器打开 则弹出提示
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          alert("balabala。。。");
        } else {
          const c = document.querySelector("body");
          c.style.zoom = 1 / devicePixelRatio; // 修改页面的缩放比例
        }
      }
    },
    setRateData() {
      this.studentList = [];
      let averageRateTotal = 0;
      let maxRateTotal = 0;
      let rateTotal = 0;
      let manAverageRateTotal = 0;
      let femaleAverageRateTotal = 0;
      let exerciseIntensityTotal = 0;
      let kinematicDensityTotal = 0;

      let targetTimeTotal = 0;
      for (let index = 0; index < student.length; index++) {
        let record = student[index];
        let studentObj = {
          ...record,
          id: index + 1,
          name: record.name,
          studentId: index + 1,
          averageRate: rand(80, 100),
          maxRate: rand(80, 100),
          rate: rand(80, 100),
          flight: 2202,
          sex: record.sex == "男" ? 1 : 2,
          isOffline: 1,
          group: Math.floor(index / 8) + 1,
          completion: 0,
          accuracy: 0,
          exerciseIntensity: 0,
          kinematicDensity: 0,
          attendanceStatus: 1,
          className: "2022级城市轨道交通运营服务班",
          targetHeartRate: rand(100, 110),
        };

        if (this.minute >= 0 && this.minute <= 2) {
          studentObj.rate = rand(80, 100);
        }

        if (this.minute > 2 && this.minute <= 6) {
          studentObj.rate = rand(120, 140);
        }

        if (this.minute > 6 && this.minute <= 8) {
          studentObj.rate = rand(130, 150);
        }

        if (this.minute > 8 && this.minute <= 10) {
          studentObj.rate = rand(120, 140);
        }

        if (this.minute > 10 && this.minute <= 40) {
          studentObj.rate = rand(145, 155);
        }

        if (this.minute > 40 && this.minute <= 42) {
          studentObj.rate = rand(135, 145);
        }

        if (this.minute > 42 && this.minute <= 45) {
          studentObj.rate = rand(110, 120);
        }

        if (this.minute > 45) {
          studentObj.rate = rand(80, 100);
        }

        // 标准度 完成度
        if (this.minute >= 6 && this.minute <= 10) {
          studentObj.completion = rand(80, 89);
          studentObj.accuracy = rand(80, 89);
          if (studentObj.group == 4) {
            studentObj.completion = rand(88, 89);
            studentObj.accuracy = rand(88, 89);
          }
          if (studentObj.name == "程思维") {
            studentObj.completion = rand(89, 90);
            studentObj.accuracy = rand(89, 90);
          }
        }

        if (this.minute > 10 && this.minute <= 25) {
          studentObj.completion = rand(60, 69);
          studentObj.accuracy = rand(60, 69);
          if (studentObj.group == 4) {
            studentObj.completion = rand(65, 69);
            studentObj.accuracy = rand(65, 69);
          }
          if (studentObj.name == "程思维") {
            studentObj.completion = rand(69, 70);
            studentObj.accuracy = rand(69, 70);
          }
        }

        if (this.minute > 25 && this.minute <= 35) {
          studentObj.completion = rand(70, 79);
          studentObj.accuracy = rand(70, 79);
          if (studentObj.group == 4) {
            studentObj.completion = rand(75, 79);
            studentObj.accuracy = rand(75, 79);
          }
          if (studentObj.name == "程思维") {
            studentObj.completion = rand(79, 80);
            studentObj.accuracy = rand(79, 80);
          }
        }

        if (this.minute > 35) {
          studentObj.completion = rand(80, 90);
          studentObj.accuracy = rand(80, 90);
          if (studentObj.group == 4) {
            studentObj.completion = rand(90, 95);
            studentObj.accuracy = rand(90, 95);
          }
          if (studentObj.name == "程思维") {
            studentObj.completion = rand(95, 100);
            studentObj.accuracy = rand(95, 100);
          }
        }

        if (this.rateArrs[index]) {
          this.rateArrs[index] = [...this.rateArrs[index], studentObj.rate];
        } else {
          this.rateArrs[index] = [studentObj.rate];
        }

        studentObj.rateArr = this.rateArrs[index];

        if (studentObj.maxRate < studentObj.rate) {
          studentObj.maxRate = studentObj.rate;
        }

        if (studentObj.rate > 100) {
          if (!studentObj.exerciseTime) {
            studentObj.exerciseTime = 5;
          }
          // 运动密度时间
          studentObj.exerciseTime = studentObj.exerciseTime + 5;
          var t1 = studentObj.exerciseTime / this.time;
          // studentObj.exerciseIntensity =
          //   Math.floor(t1 * 100) > 100 ? 100 : Math.floor(t1 * 100);
          studentObj.exerciseIntensity = rand(70, 80);
        }

        if (studentObj.rate > 140 && studentObj.rate < 160) {
          if (!studentObj.densityTime) {
            studentObj.densityTime = 5;
          }
          // 运动强度时间
          studentObj.densityTime = studentObj.densityTime + 5;
          var t1 = studentObj.densityTime / this.time;
          // studentObj.kinematicDensity =
          //   Math.floor(t1 * 100) > 100 ? 100 : Math.floor(t1 * 100);
          studentObj.kinematicDensity = rand(70, 80);
        }

        // 靶心率
        if (studentObj.rate > studentObj.targetHeartRate) {
          if (!studentObj.targetTime) {
            studentObj.targetTime = 5;
          }

          // 靶心率时间
          studentObj.targetTime = studentObj.targetTime + 5;

          targetTimeTotal += studentObj.targetTime;
        }

        rateTotal += studentObj.rate;

        if (this.rateArrs[index] && this.rateArrs[index].length) {
          let total1 = summation(this.rateArrs[index]);
          studentObj.averageRate = Math.floor(
            total1 / this.rateArrs[index].length
          );
          studentObj.maxRate = Math.max(...this.rateArrs[index]);
        } else {
          studentObj.averageRate = studentObj.rate;
          studentObj.maxRate = studentObj.rate;
        }

        averageRateTotal += studentObj.averageRate;
        maxRateTotal += studentObj.maxRate;

        exerciseIntensityTotal += studentObj.exerciseIntensity;
        kinematicDensityTotal += studentObj.kinematicDensity;

        if (studentObj.sex == 1) {
          manAverageRateTotal += studentObj.averageRate;
        } else {
          femaleAverageRateTotal += studentObj.averageRate;
        }

        studentObj.score = Math.floor(
          (studentObj.completion * studentObj.accuracy) / 100
        );

        this.studentList.push(studentObj);
      }

      let manNum = this.studentList.filter((item) => item.sex == 1);
      let femaleNum = this.studentList.filter((item) => item.sex == 2);

      let averageRate = Math.floor(averageRateTotal / student.length);
      let maxRate = Math.floor(maxRateTotal / student.length);
      let rate = Math.floor(rateTotal / student.length);
      let manAverageRate = Math.floor(manAverageRateTotal / manNum.length);
      let femaleAverageRate = Math.floor(
        femaleAverageRateTotal / femaleNum.length
      );
      let exerciseIntensity =
        Math.floor(exerciseIntensityTotal / student.length) || 0;
      let kinematicDensity =
        Math.floor(kinematicDensityTotal / student.length) || 0;

      let t = Math.floor(targetTimeTotal / student.length) || 0;
      this.targetTime += t;

      this.averageRateArrs.push(averageRate);
      this.manAverageRateArrs.push(manAverageRate);
      this.femaleAverageRateArrs.push(femaleAverageRate);

      this.$store.commit("setStudentList", this.studentList);
      sessionStorage.setItem("averageRate", averageRate);
      sessionStorage.setItem("maxRate", maxRate);
      sessionStorage.setItem("rate", rate);

      sessionStorage.setItem("manAverageRate", manAverageRate);
      sessionStorage.setItem("femaleAverageRate", femaleAverageRate);

      sessionStorage.setItem("exerciseIntensity", exerciseIntensity);
      sessionStorage.setItem("kinematicDensity", kinematicDensity);

      sessionStorage.setItem("classTime", this.time);
      sessionStorage.setItem("targetTime", this.targetTime);

      // sessionStorage.setItem("classTimeArr", JSON.stringify(this.timeArr));
      this.$store.commit("setClassTimeArr", this.timeArr);
      this.$store.commit("setAverageArr", {
        key: "averageRateArrs",
        value: this.averageRateArrs,
      });
      this.$store.commit("setAverageArr", {
        key: "manAverageRateArrs",
        value: this.manAverageRateArrs,
      });
      this.$store.commit("setAverageArr", {
        key: "femaleAverageRateArrs",
        value: this.femaleAverageRateArrs,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}
</style>
